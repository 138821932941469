button.menu-button-mobile.m-l-1.btn.btn-primary,
button.menu-button-mobile.m-l-1.btn.btn-primary:hover {
  background-color: transparent !important;
  color: black !important;
  border: none;
  font-size: 1.5rem;
}
.primary-button {
  padding: 0.5rem 2rem !important;
  background-color: transparent !important;
  color: inherit !important;
  float: right;
  margin-top: 2rem !important;
}

input {
  outline: none !important;
}
input::placeholder {
  font-size: 1.25rem !important;
}

.bg_image {
  background: url(../Image/board.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

.w-s-n-w {
  white-space: nowrap !important;
}
.progress-bar {
  background-color: #5ED999 !important; /* Your custom color code */
}

