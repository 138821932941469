.dark-theme {
    --background-color: #393256;
    --color: white;
    --nav: #27272D;
    --box_color: #372E46;
    --gray_clr: #505152;
}

.light-theme {
    --background-color: #EDF7FF;
    --color: rgb(18, 18, 18);
    --nav: white;
    --gray_clr: #505152;
    --box_color: #EFEAF5;
}

html,
body,
input,
select,
#dropdown,
.modal-content,
.pa-1.offcanvas.offcanvas-start.show,
th,tr,td,tbody,
.dropdown-menu.show,
button {
    background-color: var(--background-color) !important;
    color: var(--color) !important;
    fill: var(--color) !important;
    border-color: var(--color) !important;
}

.box {
    background-color: var(--nav) !important;
}

.box_clr {
    background-color: var(--box_color) !important;
}

.box_shadow {
    box-shadow: 0px 0px 2px 2px var(--box_color) !important;
}

.gray_bg_clr {
    background-color: var(--gray_clr) !important;
}

.network:hover {
    background-color: var(--box_color) !important;
}

.clr{
    color: var(--color) !important;
}

// th,tr,td,tbody{
// color: var(--color) !important;
// }

.or_clr{
    background-color: orange !important;
}

.gray_clr,th{
    background-color: rgb(188, 188, 188) !important;
    color: black !important;
}